<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Comisiones procesadas</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-card-text class="pt-2 pb-2">
                            <template>
                                <v-row>
                                    <v-col cols="2">
                                        <v-menu
                                            v-model="menuStartDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="start_date"
                                                    v-model="filters.start_date"
                                                    label="Desde"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    outlined
                                                    hide-details="auto"                   
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.start_date" 
                                                @input="menuStartDate = false" 
                                                no-title
                                                locale="es"
                                                @change="handleChangeStartDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-menu
                                            v-model="menuEndDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="end_date"
                                                    v-model="filters.end_date"
                                                    label="Hasta"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    outlined
                                                    hide-details="auto"     
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.end_date" 
                                                @input="menuEndDate = false" 
                                                no-title
                                                locale="es"
                                                :min="minDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            ref="source"
                                            v-model="filters.source"
                                            :items="type_items"
                                            label="Tipo de item"
                                            outlined
                                            hide-details="auto"
                                            clearable
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            ref="status"
                                            v-model="filters.status"
                                            :items="status_list"
                                            label="Estado"
                                            outlined
                                            hide-details="auto"
                                            clearable
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete                             
                                                v-model="filters.worker_id"
                                                :items="workers"
                                                label="Trabajador"
                                                hide-details
                                                outlined
                                                :item-text="customTextWorkers"
                                                item-value="id"
                                                clearable
                                            ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2" class="pl-1">
                                        <v-btn color="blue" dark @click="handleFilterCommision">
                                            Filtrar
                                        </v-btn>
                                        <v-btn color="green" 
                                            dark
                                            class="ml-1" 
                                            @click="handlePayAll">
                                            Pagar Todo
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="commission_processed"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                        >

                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las comisiones procesadas
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.created_at="{ item }">
                                <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.start_date="{ item }">
                                <span>{{ item.start_date | moment('DD/MM/YYYY') }} - {{ item.end_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.source="{ item }">
                                <span v-if="item.source === 'product'">
                                    PRODUCTO
                                </span>
                                <span v-else-if="item.source === 'service'">
                                    SERVICIO
                                </span>
                            </template>

                            <template v-slot:item.amount="{ item }">
                                <span>{{ item.amount | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.amount_payment="{ item }">
                                <span v-if="item.amount_payment">{{ item.amount_payment | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <span :class="'label-status-commission status-' + item.status">
                                    {{ item.status }}
                                </span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon size="19" color="blue" @click="handleViewDetail(item)">
                                    fas fa-bars
                                </v-icon>
                                <v-icon v-if="item.status == 'pendiente' "
                                    size="19" 
                                    color="green" 
                                    @click="handlePayment(item)" 
                                    class="ml-3">
                                    fas fa-hand-holding-usd
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <detail-commission ref="detailCommission"></detail-commission>
        <payment-commission ref="paymentCommission"></payment-commission>
        <payment-all ref="paymentAll"></payment-all>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';

import DetailCommission from '../../../components/commissions/DetailCommission';
import PaymentAll from '../../../components/commissions/PaymentAll';
import PaymentCommission from '../../../components/commissions/PaymentCommission';

export default {
    name: 'ProcessedCommissions',
    components: {
        OfficeLogin,
        DetailCommission,
        PaymentCommission,
        PaymentAll
    },
    computed: {
        ...mapState('authentication', [
            'office_id'
        ]),
        ...mapState('commissions', [
            'commission_processed'
        ]),
        ...mapState('employees', [
            'workers'
        ]),
        ...mapState('tills', [
            'selected_till'
        ]),
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Comisiones',
                    disabled: true,
                    href: '',
                }
            ],
            menuStartDate: false,
            menuEndDate: false,
            minDate: undefined,
            filters: {
                start_date: null,
                end_date: null,
                source: null,
                office_id: null,
                worker_id: null,
                status: null
            },
            type_items: [
                { text: 'PRODUCTO', value: 'product'},
                { text: 'SERVICIO', value: 'service'}
            ],
            status_list: [
                { text: 'PENDIENTE', value: 'pendiente'},
                { text: 'PAGADA', value: 'pagada'}
            ],
            headers: [
                { text: 'FECHA PROCESO', value: 'created_at', sortable: false },
                { text: 'TRABAJADOR', value: 'worker_name', sortable: false },
                { text: 'RANGO', value: 'start_date', sortable: false },
                { text: 'TIPO', value: 'source', sortable: false },
                { text: 'COMISIÓN', value: 'amount', sortable: false },
                { text: 'COMISIÓN PAGADA', value: 'amount_payment', sortable: false },
                { text: 'ESTADO', value: 'status', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ], 
        }
    },
    methods: {
        ...mapActions('commissions', [
            'getProcessed',
            'setCommissionProcessed',
            'paymentCommission'
        ]),
        ...mapActions('employees', [
            'getWorkers'
        ]),
        customTextWorkers (item) {
            let text = item.fullname
            
            return text
        },
        handleChangeStartDate (value) {
            this.minDate = value;
            console.log(value);
        },
        handleFilterCommision () {
            this.getProcessed(this.filters);
        },
        clearFilters () {
            this.filters.start_date = null;
            this.filters.end_date = null;
            this.filters.source = null;
            this.filters.worker_id = null;
            this.filters.status = null;
        },
        handleViewDetail (commission) {
            this.$refs.detailCommission.show(commission);
        },
        handlePayment (commission) {
            this.$refs.paymentCommission.show(commission);
        },
        handlePayAll () {
            let commission_id = [];
            let total = 0;
            this.commission_processed.forEach( element => {
                if (element.status == 'pendiente') {
                    commission_id.push(element.id);
                    let totalItem = parseFloat(element.amount);
                    total += totalItem;
                }
            });
            if (total > 0) {
                this.$refs.paymentAll.show(commission_id, total);
            } else {
                this.$swal({
                    title: "Pagar Todo",
                    text: "No existen comisiones pendientes de pago",
                    icon: "info",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar",
                });
            }
        },
        load () {
            this.filters.office_id = this.office_id;
            this.getProcessed(this.filters);
        }
    },
    created () {
        this.load();
        this.getWorkers({
            office_id: this.office_id
        })
    },
    destroyed () {
        this.setCommissionProcessed([]);
    }
}
</script>

<style scoped>
.label-status-commission{
    display: inline-block;
    border-radius: 4px;
    width: 85px;
    text-align: center;
    font-size: .8rem;
}

.status-pagada{
    border: 1px solid #4CAF50;
    color: #4CAF50;
}

.status-pendiente{
    border: 1px solid #F44336;
    color: #F44336;
}
</style>