<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Pagar todas las comisiones pendientes</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            :value="form.calculated_id.length"
                            label="Número de comisiones" 
                            outlined
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="form.total_amount"
                            label="Monto total de comisiones" 
                            outlined
                            prefix="S/"
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="payment_method"
                            v-model="form.payment_method"
                            label="Pagar desde"
                            :items="payment_methods"
                            outlined
                            hide-details="auto"
                            @change="handleChangePaymentMethod"
                            :rules="[() => !!form.payment_method || 'Debe seleccionar la forma de pago']">
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="form.payment_method == 'CUENTA BANCARIA'">
                        <v-autocomplete
                            ref="bank_account_id"
                            v-model="form.bank_account_id"
                            :items="accounts"
                            outlined
                            label="Cuenta Bancaria"
                            persistent-hint
                            clearable
                            :item-text="customTextAccount"
                            item-value="id"
                            hide-details="auto"
                            :filter="customFilterAccount"
                            :rules="[() => !!form.bank_account_id || 'Debe seleccionar cuenta bancaria']"
                        >
                            <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title>{{ data.item.bank.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ data.item.type }}: {{ data.item.number_account }}</v-list-item-subtitle>
                            </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row justify="center">
                    <v-col cols="6">
                        <v-btn dark 
                            color="red" 
                            block 
                            height="40" 
                            @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark 
                            color="success" 
                            block 
                            height="40" 
                            @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Pagar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'PaymentAll',
    data () {
        return {
            drawer: false,
            payment_methods: ['CAJA', 'CUENTA BANCARIA'],
            form: {
                office_id: null,
                till_id: null,
                calculated_id: [],
                payment_method: null,
                bank_account_id: null,
                total_amount: null                
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id'
        ]),
        ...mapState('accounts', [
            'accounts'
        ]),
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('accounts', [
            'getAccounts',
        ]),        
        ...mapActions('commissions', [
            'getProcessed',
            'payAllCommissions'
        ]),
        async show (calculated_id, total_amount) {
            await this.clearData();
            this.form.calculated_id = calculated_id;
            this.form.total_amount = total_amount;
            this.drawer = true;
        },
        clearData() {
            this.form.office_id = null;
            this.form.till_id = null;
            this.form.calculated_id = [];
            this.form.payment_method = null;
            this.form.bank_account_id = null;
            this.form.total_amount = null;
            this.clearErrors();
        },
        customTextAccount(item) {
            let text = item.bank.name;
            return text;
        },
        customFilterAccount(item, queryText, itemText) {
            let bank_name = item.bank.name;
            let textBank = bank_name.toLowerCase();
            let textNumberAccount = item.number_account;
            let searchText = '';
            if (queryText) {
                searchText = queryText.toLowerCase();
            }

            return (
                textBank.indexOf(searchText) > -1 ||
                textNumberAccount.indexOf(searchText) > -1
            );
        },
        handleChangePaymentMethod (value) {
            this.form.bank_account_id = null;
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleCancel() {
            this.closeForm()
        },
        async handleStore () {
            if (!this.existErrors()) {
                console.log(this.form);
                this.form.office_id = this.office_id;
                this.form.till_id = this.selected_till.id;
                await this.payAllCommissions(this.form);
                this.closeForm();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    },
    mounted () {
        this.getAccounts();
    }
}
</script>

<style scoped>

</style>