<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="900"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Detalle de comisiones</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="summary">
            <template>
                <v-card>
                    <v-card-title class="pt-1 pb-1">
                        <span class="subtitle-2">RESUMEN</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-1 pb-1">
                        <v-container>
                            <v-row>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">TRABAJADOR</span>
                                </v-col>
                                <v-col cols="4" class="pt-1 pb-1">
                                    <span>{{ summary.worker_name }}</span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">TIPO</span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span v-if="summary.source === 'product'">
                                        PRODUCTO
                                    </span>
                                    <span v-else-if="summary.source === 'service'">
                                        SERVICIO
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">RANGO</span>
                                </v-col>
                                <v-col cols="4" class="pt-1 pb-1">
                                    <span v-if="summary">
                                        {{ summary.start_date | moment('DD/MM/YYYY') }} - {{ summary.end_date | moment('DD/MM/YYYY') }}
                                    </span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">ESTADO</span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span :class="'status-'+summary.status">{{ summary.status | uppercase }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">COMISIÓN</span>
                                </v-col>
                                <v-col cols="4" class="pt-1 pb-1">
                                    <span>{{ summary.amount | currency('S/') }}</span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span class="subtitle-2">M. PAGADO</span>
                                </v-col>
                                <v-col cols="2" class="pt-1 pb-1">
                                    <span v-if="summary.amount_payment">
                                        {{ summary.amount_payment | currency('S/') }}
                                    </span>
                                    <span v-else>
                                        S/ 0.00
                                    </span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>

                <v-card class="mt-3">
                    <v-data-table
                        :headers="headers"
                        :items="commission_detail"
                        :items-per-page="10"
                        class="elevation-1 w-table"
                        height="57vh"
                    >
                        <template v-slot:item.register_date="{ item }">
                                <span>{{ item.register_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.hour_date="{ item }">
                                <span>{{ item.created_at | moment('hh:mm') }}</span>
                            </template>

                            <template v-slot:item.total="{ item }">
                                <span>{{ item.total | currency('S/') }}</span>
                            </template>
                    </v-data-table>
                </v-card>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'DetailCommission',
    data () {
        return {
            drawer: false,
            summary: {},
            headers: [
                { text: 'FECHA', value: 'register_date', sortable: false },
                { text: 'HORA', value: 'hour_date', sortable: false },
                { text: 'ITEM', value: 'description', sortable: false },
                { text: 'CANTIDAD', value: 'quantity', sortable: false },
                { text: 'TOTAL', value: 'total', sortable: false },
            ], 
        }
    },
    computed: {
        ...mapState('commissions', [
            'commission_detail'
        ])
    },
    methods: {
        ...mapActions('commissions', [
            'getCommissionDetail'
        ]),
        async show (summary) {
            this.summary = summary;
            this.drawer = true;
            await this.getCommissionDetail({
                calculated_id: summary.id
            });
        }
    }
}
</script>

<style scoped>
.status-pagada{
    font-weight: bold;
    color: #4CAF50;
}

.status-pendiente{
    font-weight: bold;
    color: #F44336;
}
</style>